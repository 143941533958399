<template>
  <div class="w-full h-full flex flex-col justify-center items-center space-y-3">
    <div class="text-2xl font-bold text-gray-800">GEREJA KRISTEN INDONESIA</div>
    <div class="text-2xl font-bold text-gray-800">NMID : ID1020032753151</div>
    <img class="w-5/6" src="~@/assets/offering/qronly.jpeg" alt="offering">
    <a
      :href="qrImage"
      class="text-xl text-blue-700 underline cursor-pointer"
      download
    >Download QR diatas</a>
    <YoutubeIframe
      v-if="youtubeId"
      :youtube-id="youtubeId"
    />
    <div class="text-base text-gray-800 text-center">Tekan tombol dibawah untuk melihat animasi panduan pemberian persembahan menggunakan dompet digital:</div>
    <div class="flex w-full space-x-4">
      <MaterialButton
        v-for="qr in qrOne"
        :key="qr.label"
        @click="changeYoutubeId(qr)"
        class="w-2/6">{{ qr.label }}</MaterialButton>
    </div>
    <div class="flex w-full space-x-4">
      <MaterialButton
        v-for="qr in qrTwo"
        :key="qr.label"
        @click="changeYoutubeId(qr)"
        class="w-2/6">{{ qr.label }}</MaterialButton>
    </div>
  </div>
</template>

<script>
import { defineAsyncComponent } from 'vue'
import qr from '@/assets/offering/qronly.jpeg'
const YoutubeIframe = defineAsyncComponent(() => import('@/components/common/YoutubeIframe.vue'))
const MaterialButton = defineAsyncComponent(() => import('@/components/common/MaterialButton.vue'))

export default {
  name: 'OfferingQr',
  data () {
    return {
      youtubeId: 'p8EWVj3MQX0'
    }
  },
  components: {
    MaterialButton,
    YoutubeIframe
  },
  computed: {
    qrOne () {
      return [
        {
          label: 'm-BCA',
          youtubeId: 'p8EWVj3MQX0'
        },
        {
          label: 'GoPay',
          youtubeId: 'PyD2U_SKibo'
        },
        {
          label: 'Ovo',
          youtubeId: 'cx25O7KY1L0'
        }
      ]
    },
    qrTwo () {
      return [
        {
          label: 'Dana',
          youtubeId: 'gNrcsLgFr7Y'
        },
        {
          label: 'Link Aja',
          youtubeId: 'JxdbD_cNkU8'
        },
        {
          label: 'Maybank',
          youtubeId: '_idXSuvmcYo'
        }
      ]
    },
    qrImage () {
      return qr
    }
  },
  methods: {
    changeYoutubeId ({ youtubeId }) {
      this.youtubeId = youtubeId
    }
  }
}
</script>

<style scoped>

</style>
